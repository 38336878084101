import voterUrl from "../assets/images/instructions/voter-url.png";
import searchInput from "../assets/images/instructions/search-input.png";
import mapKey from "../assets/images/instructions/map-key.png";
import voterCount from "../assets/images/instructions/voter-count.png";
import voterDetails from "../assets/images/instructions/voter-details.png";
import searchIcon from "../assets/images/instructions/search-icon.png";
import searchNameInput from "../assets/images/instructions/search-name-input.png";

const Instructions = () => {
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <header className="mb-8">
        <h1 className="text-3xl font-bold text-gray-800">
          Voter Record Instructions
        </h1>
        <p className="text-gray-600">
          Follow the guide below for using the voter record system.
        </p>
      </header>

      <div className="space-y-8">
        {/* Voter URL Instructions */}
        <section className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Share Voter Record
          </h2>

          <p className="text-gray-600">
            Every voter record, when opened, has a corresponding URL you can cut
            and paste to send to friends, family, neighbors, and social media.
          </p>

          <div className="mt-4 w-full rounded-md">
            <img src={voterUrl} alt="Voter URL" className="max-w-full" />
          </div>
        </section>

        {/* Search Address Instructions */}
        <section className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Search Address
          </h2>

          <p className="text-gray-600">
            The Search Address box (top right) works statewide (with autofill)
            to zoom in and center on that address.
          </p>

          <div className="mt-4 w-full rounded-md">
            <img src={searchInput} alt="Search Input" className="max-w-full" />
          </div>
        </section>

        {/* Key Icon Instructions */}
        <section className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Map Key</h2>

          <p className="text-gray-600">
            The Key icon (top left) displays the state map key for the voters'
            current election and election history. We update it regularly
            (usually nightly) as counties update the state records.
          </p>

          <div className="mt-4 w-full rounded-md">
            <img src={mapKey} alt="Map key" className="max-w-full" />
          </div>
        </section>

        {/* Voter Status Instructions */}
        <section className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-semibold text-gray-800">Voter Status</h2>

          <p className="text-gray-600 mb-0">
            The voter count in the area is displayed in the "donut cluster"
            circle at zoom levels 7-15 on the map.
            <br />
            The voter statuses:
          </p>

          <ul className="list-disc list-inside text-gray-600">
            <li>
              <span className="text-red-500 font-bold">Red</span>: Yet to Vote
            </li>
            <li>
              <span className="text-green-500 font-bold">Green</span>: Requested
              Mail Ballot
            </li>
            <li>
              <span className="text-blue-500 font-bold">Blue</span>: Returned
              Ballot/Vote
            </li>
          </ul>

          <div className="mt-4 w-full rounded-md">
            <img src={voterCount} alt="Map key" className="max-w-full" />
          </div>
        </section>

        {/* Zoom Levels Instructions */}
        <section className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Zoomed-in Voter Details
          </h2>

          <p className="text-gray-600 mb-0">
            At zoom levels 16-22 on the map (when zoomed in closely), each voter
            is represented by a colored pin based on their current election
            status:
          </p>

          <ul className="list-disc list-inside text-gray-600">
            <li>
              <span className="text-red-500 font-bold">Red</span>: Yet to Vote
            </li>
            <li>
              <span className="text-green-500 font-bold">Green</span>: Requested
              Mail Ballot
            </li>
            <li>
              <span className="text-blue-500 font-bold">Blue</span>: Returned
              Ballot/Vote
            </li>
          </ul>

          <div className="mt-4 w-full rounded-md">
            <img src={voterDetails} alt="Map key" className="max-w-full" />
          </div>
        </section>

        <section className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Voter Display and Search Functionality
          </h2>

          <p className="text-gray-600 mb-0">
            At zoom levels 16-22 on the map (when zoomed in closely), each voter
            is displayed along with the Voter Search icon, allowing voters to be
            searched by name.
            <br />
            <strong>Note</strong>: You can only search by name among the
            displayed pins; statewide searches by name are not available.
          </p>

          <div className="mt-4 w-full rounded-md">
            <img src={searchIcon} alt="Map key" className="max-w-full" />
          </div>
        </section>

        <section className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Search By Name: Important Note
          </h2>

          <p className="text-gray-600 mb-0">
            <strong>Note</strong>: Searching by Name filters the displayed pins.
            Remember to X out the Search by Name, or the pins will remain
            hidden.
          </p>

          <div className="mt-4 w-full rounded-md">
            <img src={searchNameInput} alt="Map key" className="max-w-full" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Instructions;
