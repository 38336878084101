import { useState } from "react";
import clsx from "clsx";
import donate_banner from "./../assets/donate_banner.gif";

const Header = ({ setInstructionsModalOpen, setAboutUsModalOpen, title }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const [isMobileDropDownOpen, setMobileDropDownOpen] = useState(false);

  const [isDesktopDropDownOpen, setDesktopDropDownOpen] = useState(false);

  return (
    <header
      className="bg-white lg:bg-primary relative z-[999999]"
      style={{ zIndex: 1000, width: "100%" }}
    >
      <div className="flex justify-between items-center mx-auto">
        {/* <!-- Banner --> */}
        <div
          className="max-w-[480px]"
          onclick="window.open('https://donorbox.org/pa-voter-maps-x-ad-campaign','_blank')"
        >
          <a
            href="https://donorbox.org/pa-voter-maps-x-ad-campaign"
            target="_blank"
            className=""
          >
            <img
              src={donate_banner}
              alt="donate"
              className="w-full max-w-full"
            />
          </a>
        </div>

        <div className="hidden lg:block">
          <p className="text-xl font-bold text-white uppercase">{title}</p>
        </div>

        {/* <!-- Humberger btn icon --> */}
        <div className="px-4 lg:hidden" onClick={() => setMenuOpen(true)}>
          <button className="humberger-btn w-6 h-6">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"
                className="fill-primary"
              />
            </svg>
          </button>
        </div>

        {/* <!-- Mobile menu  --> */}
        <div
          className={clsx(
            "mobile-menu absolute top-0 left-0 w-screen h-screen overflow-hidden z-50 bg-white lg:hidden",
            isMenuOpen ? "block" : "hidden"
          )}
        >
          <div className="flex justify-end px-4 py-2 pt-4">
            <button
              className="x-btn w-6 px-4 py-2 box-content"
              onClick={() => setMenuOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path
                  d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
                  className="fill-primary"
                />
              </svg>
            </button>
          </div>

          <div className="mt-10">
            <ul className="flex flex-col gap-2 px-8">
              <li className="">
                <button
                  href=""
                  className="drop-down-link-m flex justify-between items-center w-full"
                  onClick={() => setMobileDropDownOpen((prev) => !prev)}
                >
                  <span className="text-primary font-bold">STATES</span>

                  <div className="">
                    <div className="w-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                          className="fill-primary"
                        />
                      </svg>
                    </div>
                  </div>
                </button>

                {/* <!-- Submenu dropdown --> */}
                <ul
                  className={clsx(
                    "drop-down-menu-m ps-4 py-2",
                    isMobileDropDownOpen ? "block" : "hidden"
                  )}
                >
                  <li className="">
                    <a
                      href="https://votermaps.org/?#6.29/40.54/-78.017"
                      className="text-primary block w-full"
                      style={{ textAlign: "left" }}
                    >
                      HOME
                    </a>
                  </li>

                  <li className="">
                    <a
                      href="https://pennsylvania.votermaps.org/?#6.29/40.54/-78.017"
                      className="text-primary block w-full"
                      style={{ textAlign: "left" }}
                    >
                      PENNSYLVANIA
                    </a>
                  </li>

                  <li className="">
                    <a
                      href="https://northcarolina.votermaps.org/?#7/35.189/-79.891"
                      className="text-primary block w-full"
                      style={{ textAlign: "left" }}
                    >
                      NORTH CAROLINA
                    </a>
                  </li>

                  <li className="">
                    <a
                      href="https://florida.votermaps.org/?#7.1/28.99/-83.303"
                      className="text-primary block w-full"
                      style={{ textAlign: "left" }}
                    >
                      FLORIDA (TEST)
                    </a>
                  </li>

                  <li className="">
                    <a
                      href="https://ohio.votermaps.org/?#7.1/28.99/-83.303"
                      className="text-primary block w-full"
                      style={{ textAlign: "left" }}
                    >
                      OHIO (TEST)
                    </a>
                  </li>
                </ul>
              </li>

              <li className="">
                <button
                  href=""
                  className="text-primary font-bold block"
                  style={{ textAlign: "left" }}
                  onClick={() => setAboutUsModalOpen(true)}
                >
                  ABOUT US
                </button>
              </li>

              <li className="">
                <button
                  href=""
                  className="text-primary font-bold block"
                  style={{ textAlign: "left" }}
                  onClick={() => setInstructionsModalOpen(true)}
                >
                  INSTRUCTIONS
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* <!-- Desktop menu version --> */}
        <div className="pr-10 lg:flex items-center hidden">
          <ul className="flex items-center gap-8">
            <li className="relative">
              <button
                href="#"
                className="drop-down-link-d text-left"
                onMouseEnter={() => setDesktopDropDownOpen(true)}
                onMouseLeave={() => setDesktopDropDownOpen(false)}
              >
                <span className="text-white font-bold">STATES</span>

                <div
                  className={clsx(
                    "drop-down-menu-d absolute top-full h-6 w-full",
                    isDesktopDropDownOpen ? "block" : "hidden"
                  )}
                >
                  <ul className="absolute top-full border-2 border-primary left-0 flex-col bg-white shadow-md rounded-md py-2 w-[180px]">
                    <li className="">
                      <a
                        href="https://votermaps.org/?#6.29/40.54/-78.017"
                        className="text-primary py-1 hover:bg-primary hover:text-white block w-full px-2"
                      >
                        HOME
                      </a>
                    </li>

                    <li className="">
                      <a
                        href="https://pennsylvania.votermaps.org/?#6.29/40.54/-78.017"
                        className="text-primary py-1 hover:bg-primary hover:text-white block w-full px-2"
                      >
                        PENNSYLVANIA
                      </a>
                    </li>

                    <li className="">
                      <a
                        href="https://northcarolina.votermaps.org/?#7/35.189/-79.891"
                        className="text-primary py-1 hover:bg-primary hover:text-white block w-full px-2"
                      >
                        NORTH CAROLINA
                      </a>
                    </li>

                    <li className="">
                      <a
                        href="https://florida.votermaps.org/?#7.1/28.99/-83.303"
                        className="text-primary py-1 hover:bg-primary hover:text-white block w-full px-2"
                      >
                        FLORIDA (TEST)
                      </a>
                    </li>

                    <li className="">
                      <a
                        href="https://ohio.votermaps.org/?#7.1/28.99/-83.303"
                        className="text-primary py-1 hover:bg-primary hover:text-white block w-full px-2"
                      >
                        OHIO (TEST)
                      </a>
                    </li>
                  </ul>
                </div>
              </button>
            </li>

            <li>
              <button
                href="#"
                className="text-white font-bold"
                onClick={() => setAboutUsModalOpen(true)}
              >
                ABOUT US
              </button>
            </li>

            <li>
              <button
                href="#"
                className="text-white font-bold"
                onClick={() => setInstructionsModalOpen(true)}
              >
                INSTRUCTIONS
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
